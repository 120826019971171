(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("ibm-gantt-chart"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "ibm-gantt-chart"], factory);
	else if(typeof exports === 'object')
		exports["ibm-gantt-chart-react"] = factory(require("prop-types"), require("react"), require("ibm-gantt-chart"));
	else
		root["ibm-gantt-chart-react"] = factory(root["PropTypes"], root["React"], root[undefined]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 